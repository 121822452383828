@import "../../styles/variebles.scss";

.card {
  width: 337px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  font-weight: 700;
  border-radius: 12px;
  background-color: $component_bg_color;
  @media (max-width: 767px) {
    width: 150px;
    padding: 5px 10px;
    font-weight: 500;
  }
  &__title {
    color: $text_color;
    font-size: $item_size;
    margin-bottom: 8px;
    @media (max-width: 767px) {
      font-size: $item_size_mobile;
      margin-bottom: 4px;
    }
  }
  &__sub-title {
    color: $title_color;
    font-size: $card_title_size;
    margin-bottom: 8px;
    @media (max-width: 767px) {
      font-size: $item_size_mobile;
      margin-bottom: 4px;
    }
  }
  &__description {
    color: #d7d7d7;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: $item_size_mobile;
    }
  }
  &__diagram {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
