@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import "./variebles.scss";

html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  background-color: $bg_color;
  line-height: 131.9%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  display: block;
  text-decoration: none;
  color: $text_color;
}

input {
  display: block;
  outline: none;
  border: none;
}

.app {
    display: flex;
    @media (max-width: 767px) {
      display: block;
    }
}

.containter {
    min-width: 1140px;
    margin: 0 auto;
    padding: 0px 10px;
    @media (max-width: 767px) {
      min-width: 420px;
      padding: 0 5px;

    }

}
