@import "../../styles/variebles.scss";

.page-header {
  margin-bottom: 15px;
  &__title {
    color: $title_color;
    font-size: $page_title_size;
    font-weight: 700;
  }
}
