.dashboard {
  &__statistic-list {
    display: flex;
    gap: 54px;
    margin-bottom: 16px;
    @media (max-width: 767px) {
      gap: 2px;
    }
  }
}
