// Define main colors, padding, and border-radius to match the overall design
$primary-color: #fff; // Assuming a dark color scheme from the screenshot
$secondary-color: #6c6c83;; // White color for contrast
$border-color: #cccccc; // Light grey border color
$primary-font-size: 14px;
$button-background-color: #5b5b5b; // Button color observed in the screenshot
$button-text-color: $secondary-color;
$border-radius: 4px;
$padding: 10px;

.form {
  background-color: #383854;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: $padding;
  font-size: $primary-font-size;
  color: $primary-color;

  &__wrapper {
    margin-bottom: $padding;
  }

  &__field {
    width: 100%;
    padding: $padding;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    font-size: $primary-font-size;
    color: $primary-color;
    background-color: #383854;

    &::placeholder {
      color: $primary-color;
    }

    &:focus {
      outline: none;
      border-color: darken($border-color, 10%);
    }
  }

  &__text-area {
    height: 220px;
    resize: none;
    @media (max-width: 767px) {
      height: 120px;
    }
  }

  &__error-message {
    color: rgba(224, 15, 15, 0.849);
    font-size: $primary-font-size * 1.2;
    font-weight: 600;
  }

  &__file {
    border: none;
    padding: 0;
    margin-top: $padding;

    &.hidden {
      display: none;
    }

    + label {
      display: block;
      background-color: $button-background-color;
      color: $button-text-color;
      text-align: center;
      padding: $padding;
      border-radius: $border-radius;
      cursor: pointer;

      &:hover {
        background-color: lighten($button-background-color, 10%);
      }
    }
  }
}
