@import "../../styles/variebles.scss";

.search-panel {
  background-color: $component_bg_color;
  padding: 20px 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  &__input {
    width: 100%;
    padding: 8px 10px;
    font-size: $item_size;
    border-radius: 6px;
    font-weight: 700;
    color: rgba($color: #000000, $alpha: 0.7);
  }
  &__filters-title {
    font-size: $component_title_size;
    color: $text_color;
  }
  &__buttons-wrapper {
    display: flex;
    gap: 5px;
  }
  &__button {
    padding: 10px 25px;
    border: 1px solid rgba($color: #fff, $alpha: 0.8);
    border-radius: 6px;
    background-color: initial;
    color: $text_color;
    font-size: $item_size;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    &-active {
      background-color: rgba($color: $active_bg_color, $alpha: 0.7);
    }
  }
}
