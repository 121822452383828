.transactions {
  &__header {
    text-align: center;
    margin-bottom: 15px;
    &__title {
      font-size: 20px;
      color: #fff;
      font-weight: 600;
    }
  }
}
