@import "../../styles/variebles.scss";

.modal {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background: $modal_bg_color;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in;
  @media (max-width: 767px) {
    top: 45%;
    width: 390px;
  }
  &__close {
    display: none;
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: $page_title_size;
    color: $title_color;
    @media (max-width: 767px) {
      font-size: $page_title_size_mobile;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__label {
    margin-bottom: 10px;
    color: rgba($color: $title_color, $alpha: 0.7);
    font-size: $component_title_size;
    font-weight: 600;
  }

  &__input,
  &__text-area {
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: $item_size;
    @media (max-width: 767px) {
      font-size: $item_size_mobile;
    }

    &:focus {
      border-color: #0056b3;
      outline: none;
    }
  }

  &__text-area {
    resize: none;
  }

  &__button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;

    &--saved {
      background-color: #168012;
      color: white;
    }

    &--save {
      background-color: #007bff;
      color: white;

      &:hover {
        background-color: darken(#007bff, 10%);
      }
    }

    &--close {
      background-color: #6c757d;
      color: white;
      margin-top: 10px;

      &:hover {
        background-color: darken(#6c757d, 10%);
      }
    }
  }
}
