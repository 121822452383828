@import "../../styles/variebles.scss";

.employee {
  padding: 16px 20px;
  border-radius: 12px;
  background-color: $component_bg_color;
  &__top {
    margin-bottom: 15px;
  }
  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #383856;
    padding: 10px 5px;
    &-error {
      padding: 0 5px;
      text-align: left;
      font-size: $component_title_size;
      color: darken($color: #ff4f00, $amount: 3);
      font-weight: 700;
    }

  }
  &__input {
    min-width: 300px;
    &::placeholder {
      color: rgba($color: $title_color, $alpha: 0.6);
      font-weight: 500;
    }
  }
  &__select {
    width: 300px;
    margin-bottom: 8px;
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    font-size: $component_title_size;
    padding: 5px 15px;
    color: rgba($color: $title_color, $alpha: 0.6);
    font-weight: 500;
    &::selection {
        background-color: rgba($color: #000000, $alpha: 0.2);

    }
  }
  &__button {
    margin: unset;
  }
}
