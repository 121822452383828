@import "../../styles/variebles.scss";

.settings {
  padding: 16px 20px;
  border-radius: 12px;
  background-color: $component_bg_color;
  margin-bottom: 16px;
  &__fieldset {
    padding: 20px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;

    }
    &-textarea {
      margin-bottom: 80px;
      grid-template-columns: 1.5fr 1.5fr;
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
  
      }
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__legend {
    font-size: $page_title_size;
    color: $title_color;
    font-weight: 700;
    @media (max-width: 767px) {
      font-size: $page_title_size_mobile;
    }
  }
  &__label {
    display: block;
    margin-bottom: 5px;
  }
  &__label {
    color: rgba($color: $title_color, $alpha: 0.7);
    font-size: $component_title_size;
    font-weight: 600;
  }
  &__input-wrapper {
    max-width: 200px;
  }
  &__area-wrapper {
    min-width: 50%;
  }
  &__input {
    margin-bottom: 8px;
    width: 200px;
    border-radius: 6px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    font-size: $component_title_size;
    padding: 5px 15px;
    color: rgba($color: $title_color, $alpha: 0.6);
    font-weight: 500;
    &--address {
      width: 350px;
    }
  }
  &__textarea {
    height: 260px;
    width: 100%;
    resize: none;
  }
  &__button {
    display: block;
    padding: 10px 25px;
    border: 1px solid rgba($color: #fff, $alpha: 0.8);
    border-radius: 6px;
    background-color: rgba($color: $active_bg_color, $alpha: 0.7);
    color: $text_color;
    font-size: $item_size;
    font-weight: 600;
    cursor: pointer;
    margin: 0 auto;
    &.success {
      background-color: #4caf50;
      color: white;
      svg.icon-success {
        fill: white;
        transition: opacity 0.3s;
        opacity: 1;
      }
    }
  }
}

