.error-container {
    padding: 10px 20px;
    background-color: #ffd6d9;
    color: #611a1f; 
    border-radius: 5px; 
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    @media (max-width: 767px) {
        padding: 5px;
        max-width: 100%;
    }
}

.error-message {
    font-size: 18px; 
    font-weight: 500;
}
