.mobile-header {
  width: 100%;
  display: none;
  // display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(153, 9, 57, 0.2);
  &__title {
    font-size: 24px;
    color: #fff;
  }
  @media (max-width: 767px) {
    display: flex;
  }
}
