@import "../../styles/variebles.scss";

.settings-header {
  width: 100%;
  margin-bottom: 16px;
  padding: 15px;
  border-radius: 12px;
  background-color: $component_bg_color;
  font-size: 15px;
  color: $title_color;
  &__list {
    display: flex;
    justify-content: space-around;
    &-item {
      border-radius: 12px;
      padding: 5px 15px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &--selected {
        background-color: $active_bg_color;
      }
    }
  }
}
