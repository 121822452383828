.header {
    text-align: center;
    margin: 0;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    opacity: .9;
    &__title {
        color: #fff;
        font-size: 24px;
        margin-bottom: 8px;
        font-weight: 700;
    }
    &__description {
        color: #fff;
        font-size: 18px;
    }
}