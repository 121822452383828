.list-item {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__left-block {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__left-block::before {
    content: "";
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #242426;
    background-image: url("../../assets/arrows-medium.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
  &__title {
    display: block;
    font-size: 15px;
    color: #fff;
    // margin-bottom: 5px;
    font-weight: 600;
  }
  &__sub-title {
    font-size: 13px;
    color: #78818e;
    font-weight: 500;
  }

  &__amount {
    font-size: 14px;
    color: #48ff91;
    font-weight: 600;
  }
}
