$bg_color: #383854;
$modal_bg_color: #26263b;
$component_bg_color: #2E2E48;
$active_bg_color: #475BE8;
$table_header_color: #383854;
$title_color: #fff;
$text_color: #E1E1E1;
$page_title_size: 22px;
$card_title_size: 24px;
$component_title_size: 16px;
$item_size: 14px;
$page_title_size_mobile: 18px;
$card_title_size_mobile: 20px;
$component_title_size_mobile: 14px;
$item_size_mobile: 12px; 


