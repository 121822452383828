.toggle_button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px; 
  cursor: pointer; 
  color: #ffffff;
  transition: background-color 0.3s; 

  &.true {
    background-color: #27ae60;

    &:hover {
      background-color: darken(#27ae60, 10%);
    }
  }

  &.false {
    background-color: #c0392b;

    &:hover {
      background-color: darken(#c0392b, 10%);
    }
  }

  &.change {
    background-color: #007bff;

    &:hover {
      background-color: darken(#007bff, 10%);
    }
  }
}

.spinner {
  display: block;
  &__wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
}