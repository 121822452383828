@import "../../styles/variebles.scss";

.sidebar {
  width: 209px;
  min-height: 100dvh;
  background-color: $component_bg_color;
  padding: 16px 12px 49px 28px;
  @media (max-width: 767px) {
    position: fixed;
    // display: none;
    z-index: 10;
    top: 72px;
    left: -209px;
    transition: left 0.3s ease-out;
  }
  &__visible {
    // display: block;
    left: 0;
  }
  &__title {
    font-size: $page_title_size;
    font-weight: 900;
    color: $title_color;
    margin-bottom: 49px;
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__navigation-list {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  &__navigation-item {
    display: flex;
    align-items: center;
    max-width: 185px;
    min-height: 45px;
    padding-left: 12px;
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
    &--selected {
        background-color: $active_bg_color;
    }
  }
  &__link {
    font-weight: 600;
    font-size: $item_size;
    margin-left: 11px;
    cursor: pointer;
  }

}
