@import "../../styles/variebles.scss";

.table {
  width: 100%;
  border-spacing: 0;
  border-radius: 6px;
  border: 2px solid #383856;
  @media (max-width: 767px) {
    border: none;
  }

  &__mobile-hide {
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__wrapper {
    background-color: $component_bg_color;
    padding: 8px;
    border-radius: 12px;
    margin-bottom: 16px;
    overflow-x: auto;
    @media (max-width: 767px) {
      // width: 390px;
    }
  }
  &__title {
    color: $title_color;
    font-size: $component_title_size;
    font-weight: 700;
    @media (max-width: 767px) {
      font-size: $component_title_size_mobile;
      font-weight: 500;
    }
  }
  &__head-title {
    background-color: $bg_color;
    text-align: left;
    padding: 12px 16px;
    color: #f3f3f3;
    font-size: $component_title_size;
    font-weight: 400;
    opacity: 0.9;
    @media (max-width: 767px) {
      padding: 6px 8px;
      font-size: $component_title_size_mobile;
    }
  }
  &__content {
    padding: 12px 16px;
    color: $title_color;
    font-size: $item_size;
    font-weight: 500;
    opacity: 0.7;
    &--status {
      opacity: 1;
      font-weight: 700;
      font-size: 16px;

    }
    @media (max-width: 767px) {
      padding: 6px 8px;
      font-size: $item_size_mobile;
      max-width: 83px;
    }

  }
  &__input {
    max-width: 500px;
    background-color: initial;
    padding: 7px 2px;
    text-align: left;
    color: $title_color;
    font-size: $item_size;
    font-weight: 500;
    opacity: 0.7;
    border: none;
    &:active,
    &:focus {
      outline: 1px solid #f3f3f3;
      border-radius: 6px;
    }
    @media (max-width: 767px) {
      max-width: 55px;
    }
  }
  &__large-input {
    max-width: 150px;
  }
  &__img {
    width: 50px;
    height: 50px;
  }
  &__status {
    &-success {
      color: rgba($color: #3ba112, $alpha: 1.0)
    }
    &-pending {
      color: rgba($color: #23fa07, $alpha: 1.0)
    }
    &-dispute {
      color: rgba($color: #d0ebec, $alpha: 1.0)
    }
    &-unsuccess {
      color: rgba($color: #ff0000, $alpha: 1.0)

    }
    &-reissue {
      color: rgba($color: #d6d317, $alpha: 1.0)

    }
  }
}
